/*
 * @Author: niweixing
 * @Date: 2024-05-15 01:06:51
 * @LastEditors: niweixing
 * @LastEditTime: 2024-07-09 02:08:21
 * @Description: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import "./assets/less/index.less"
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

createApp(App).use(store).use(router).use(Vant).use(VueVideoPlayer).mount('#app')
