/*
 * @Author: niweixing
 * @Date: 2024-05-15 01:06:51
 * @LastEditors: niweixing
 * @LastEditTime: 2024-07-24 22:06:06
 * @Description: 
 */

import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
// 引入组件  
import HomeView from "../views/HomeView.vue";
import store from "../store/index"

// 声明路由list
const routes: Array<RouteRecordRaw> = [
  // 路由对象，必须属性 path ， name,component
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue")
  },
  {
    path: "/regist",
    name: "regist",
    component: () => import("../views/regist.vue")
  },

  // 个人页面 主要展示帐号信息
  {
    path: "/personalHome",
    name: "personalHome",
    component: () => import("../views/personalHome.vue")
  },

  // 客服页
  {
    path: "/customerService",
    name: "customerService",
    component: () => import("../views/customerService.vue")
  },

  // 任务列表页
  {
    path: "/taskList",
    name: "linkList",
    component: () => import("../views/taskList.vue")
  },

  // 第一步
  {
    path: "/firstStep",
    name: "firstStep",
    component: () => import("../views/firstStep.vue")
  },
  // 第二步
  {
    path: "/secondStep",
    name: "secondStep",
    component: () => import("../views/secondStep.vue")
  },
  // 第一步
  {
    path: "/thirdStep",
    name: "thirdStep",
    component: () => import("../views/thirdStep.vue")
  },
  // 教程演示第一步
  {
    path: "/tutorialFirst",
    name: "tutorialFirst",
    component: () => import("../views/tutorialFirst.vue")
  },
  // 教程演示第二步
  {
    path: "/tutorialSecond",
    name: "tutorialSecond",
    component: () => import("../views/tutorialSecond.vue")
  },
  // 教程演示第三步
  {
    path: "/tutorialThird",
    name: "tutorialThird",
    component: () => import("../views/tutorialThird.vue")
  },
  // 教程演示第四步
  {
    path: "/tutorialFourth",
    name: "tutorialFourth",
    component: () => import("../views/tutorialFourth.vue")
  },

  // 任务第一步
  {
    path: "/actionFirst",
    name: "actionFirst",
    component: () => import("../views/actionFirst.vue")
  },
  // 任务第二步
  {
    path: "/actionSecond",
    name: "actionSecond",
    component: () => import("../views/actionSecond.vue")
  },
  // 任务第三步
  {
    path: "/actionThird",
    name: "actionThird",
    component: () => import("../views/actionThird.vue")
  },
  // 任务第四步
  {
    path: "/actionFourth",
    name: "actionFourth",
    component: () => import("../views/actionFourth.vue")
  },
  // 个人登录
  {
    path: "/personalLogin",
    name: "personalLogin",
    component: () => import("../views/personalLogin.vue")
  },
  // 个人注册
  {
    path: "/personalRegist",
    name: "personalRegist",
    component: () => import("../views/personalRegist.vue")
  },
  //个人信息完善
  {
    path: "/personalInformation",
    name: "personalInformation",
    component: () => import("../views/personalInformation.vue")
  },
  //个人信息完善确认提示
  {
    path: "/personalInfoCheck",
    name: "personalInfoCheck",
    component: () => import("../views/personalInfoCheck.vue")
  },
  //重置密码
  {
    path: "/personalRetrievePw",
    name: "personalRetrievePw",
    component: () => import("../views/personalRetrievePw.vue")
  },
  //更换密码 验证码页
  {
    path: "/personalChangePw",
    name: "personalChangePw",
    component: () => import("../views/personalChangePw.vue")
  },
  //更换密码 确认密码页
  {
    path: "/personalCheckPw",
    name: "personalCheckPw",
    component: () => import("../views/personalCheckPw.vue")
  },
  //领取优惠卡页
  {
    path: "/transferInformation",
    name: "transferInformation",
    component: () => import("../views/transferInformation.vue")
  },
  //分享提示页
  {
    path: "/sharePage",
    name: "sharePage",
    component: () => import("../views/sharePage.vue")
  }

]


const router = createRouter({
  history: createWebHistory(), //hash模式链接有#
  routes: routes
})
// 路由守卫
// 只要进入到某个路由就会执行此方法
// 定义全局前置守卫（里面有两个坑要注意）
router.beforeEach((to, from, next) => {
  // from 从哪个路由跳转的
  //to 到达的路由
  // 只有执行了next页面才会被渲染 
  // 判断用户是否登录
  /**
    如果用户没有登录则进行跳转到登录页的操作
    否则正常跳转
    */
  // console.log("store----",store.state)

  if (store.state.uInfo && store.state.uInfo.id) {
    // 用户已登录  
    next()
  } else {
    // 未登录
    next()
    // if(to.path=="/login"){
    //   next()
    //   return
    // }
    // next("/login")
  }
})
export default router