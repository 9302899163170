<!--
 * @Author: niweixing
 * @Date: 2024-05-15 01:06:51
 * @LastEditors: niweixing
 * @LastEditTime: 2024-07-09 01:59:44
 * @Description: 
-->
<template>
  <router-view></router-view>
</template>
<style scoped>
* {
  /* height: 1624px; */
  /* opacity: 1; */

  /* 背景色 */
  /* background: linear-gradient(180deg, #d9edfb 0%, #ffffff 100%); */
  /* background:  #F4F6F8; */
  /* background: #f6f6f6; */
}
</style>
